<template>
  <div class="demo7">
    <div id="playVideo"></div>
  </div>
</template>

<script>
import { queAnswers } from "@/api";
export default {
  data() {
    return {
      psyClient: "",
    };
  },
  mounted() {
    this.init();
    window.digitalSpeaking = this.digitalSpeaking;
  },
  methods: {
    init() {
      const userId = "6499612d07c8f4084d6a5d09";
      const ak = "6463324857e4cadc201fc791";
      const sk = "f0c03b2334c74a0b9d85a402941d9200";
      const playerElementId = "playVideo";
      this.psyClient = new PsyClient();
      this.psyClient.init(userId, ak, sk, playerElementId);
      setTimeout(() => {
        this.psyClient
          .startSession()
          .then((data) => {
            console.log(`startSession success data: ${data}`);
          })
          .catch((err) => {
            console.log("startSession failed", err);
          });
      }, 500);
    },
    digitalSpeaking(val) {
      queAnswers({
        text: val,
      }).then((res) => {
        console.log(res.value);
        this.psyClient.playMedia(res.value);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.demo7 {
  width: 100vw;
  height: 100vh;
  #playVideo {
    width: 500px;
    height: 100%;
    margin: 0 auto;
    /deep/.remote {
      position: static !important;
    }
  }
}
</style>